<template>
  <div>
    <object-code-add></object-code-add>
  </div>
</template>

<script>
import ObjectCodeAdd from './ObjectCodeAdd.vue';

export default {
  components: {
    ObjectCodeAdd,
  },

  data() {
    return {};
  },
};
</script>
